import React, { useEffect, useState } from "react";
import "./promotionalBanner.css";

const PromotionBanner = ({ userValue, catalogUrl, promotions }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [imageClass, setImageClass] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const [hasBackground, setHasBackground] = useState(false);

  useEffect(() => {
    const currentDate = new Date().getTime();

    const activePromo = promotions.find((promo) => {
      const startDate = new Date(promo.startDate).getTime();
      const endDate = new Date(promo.endDate).getTime();
      return currentDate >= startDate && currentDate <= endDate;
    });

    if (activePromo && userValue) {
      setIsVisible(true);
      setUrlValue(activePromo.promoUrl || "");
      setImageClass(activePromo.imgClass);
      // validating if the image class exists
      const img = new Image();
      img.src = `/images/${activePromo.imgClass}.jpg`; 
      console.log(img.src)
      img.onload = () => setHasBackground(true);
      img.onerror = () => setHasBackground(false);
    } else {
      setIsVisible(false);
    }
  }, [userValue, promotions]);

  return (
    isVisible && (
      <a href={`${catalogUrl}/b2r/SAML/login?supplier=${urlValue}`}>
        <div className={`pnc-center-promo pnc-gift-promotion ${imageClass} ${!hasBackground ? 'has-background' : ''}`}></div>
      </a>
    )
  );
};

export default PromotionBanner;
